import React from "react";

import Button from "components/Button";

const NotFoundPage = () => (
  <>
    <h1>Puslapis neegzistuoja. Grįžkite atgal.</h1>
    <Button isLink={true} url="/">
      Grįžti atgal
    </Button>
  </>
);

export default NotFoundPage;
